import React from 'react';
import EmptyTip from '../empty-tip';
import TemplateList from './template-list';
import { seatableMarketInternalAPI } from '../../utils/internal-api';
import Template from '../../model/template';
import { Utils } from '../../utils/utils';
import toaster from '../toast';
import Loading from '../loading';

class TemplateModule extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      category: '全部',
      isLoading: true,
      isTemplateLoaded: false,
      isExistTemplate: false,
      templateMap: {},
      templateCategories: ['全部'],
      searchParams: null
    };
  }

  componentDidMount() {
    this.loadTemplateList();
    this.onHandleUrlSearch();
  }

  onHandleUrlSearch = () => {
    let { search } = location;
    if (!search) return;
    let searchParams = Utils.getUrlSearches(search);
    this.setState({
      searchParams: searchParams
    });
  }

  onCategoryClick = (category) => {
    this.setState({category: category});
  }

  loadTemplateList = () => {

    this.setState({
      isLoading: true,
    });

    // load data;
    seatableMarketInternalAPI.listTemplates().then(res => {
      let templateList = res.data.template_list.map(template => {
        return new Template(template);
      });
      
      let { templateCategories } = this.state;
      templateList.forEach(template => {
        if (templateCategories.indexOf(template.category) === -1) {
          templateCategories.push(template.category);
        }
      });
      let templateMap = this.calculateTemplateMap(templateList, templateCategories);

      this.setState({
        isLoading: false,
        isTemplateLoaded: true,
        isExistTemplate: templateList.length > 0 ? true : false,
        templateMap: templateMap,
        templateCategories: templateCategories,
      });
    }).catch(error => {
      let errMessage = Utils.getErrorMsg(error);
      toaster.danger(errMessage);
      this.setState({isLoading: false});
    });

  }

  calculateTemplateMap = (templateList, templateCategories) => {
    let templateMap = {};
    templateCategories.forEach(category => {
      templateMap[category] = templateList.filter(template => {
        return template.category === category;
      });
    });
    return templateMap;
  }


  renderCategoryTemplates = (category) => {
    let { templateMap, searchParams } = this.state;
    return (
      <div className="template-category" key={category}>
        <div className="template-category-name">{category}</div>
        <TemplateList templateList={templateMap[category]} searchParams={searchParams} />
      </div>
    );
  }

  render() {
    let { isExistTemplate, templateCategories, category, isLoading } = this.state;

    if (isLoading) return <Loading />;
    if (!isExistTemplate) {
      return <div className="template-module">
        <EmptyTip>
          <p>{'There are none templates in the config table, please build and register.'}</p>
        </EmptyTip>
      </div>;
    }
    
    return (
      <div className="template-module">
        <div className="template-categories">
          {templateCategories.map(categoryName => {
            return <span key={categoryName} className={`template-category-item user-select-none ${categoryName === category ? 'active' : ''}`} onClick={this.onCategoryClick.bind(this, categoryName)}>{categoryName}</span>;
          })}
        </div>
        {category !== '全部' && (this.renderCategoryTemplates(category))}
        {category === '全部' && (
          templateCategories.map(category => {
            if (category === '全部') {
              return '';
            }
            return this.renderCategoryTemplates(category);
          })
        )}
      </div>
    );
  }
}

export default TemplateModule;
