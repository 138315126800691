class Plugin {

  constructor(object) {
    this.name = object.name || '';
    this.display_name = object.display_name || '';
    this.card_image_url = object.card_image_url || '';
    this.description = object.description || '';
    this.zip_asset_path = object.zip_asset_path || '';
  }
  
}

export default Plugin;