import React from 'react';
import EmptyTip from '../empty-tip';
import PluginList from './plugin-list';
import Loading from '../loading';
import Plugin from '../../model/plugin';
import { seatableMarketInternalAPI } from '../../utils/internal-api';
import { Utils } from '../../utils/utils';
import toaster from '../toast';

class PluginModule extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      pluginList: [],
      searchParams: null
    };
  }

  componentDidMount() {
    this.loadPluginList();
    this.onHandleUrlSearch();
  }

  loadPluginList = () => {
    seatableMarketInternalAPI.listPlugins().then(res => {
      let pluginList = res.data.plugin_list.map(plugin => {
        return new Plugin(plugin);
      });
      this.setState({
        pluginList: pluginList,
        isLoading: false,
      });
    }).catch(error => {
      let errMessage = Utils.getErrorMsg(error);
      toaster.danger(errMessage);
      this.setState({isLoading: false});
    });
  }

  onHandleUrlSearch = () => {
    let { search } = location;
    if (!search) return;
    let searchParams = Utils.getUrlSearches(search);
    this.setState({
      searchParams: searchParams
    });
  }

  render() {

    let { pluginList, isLoading, searchParams } = this.state;

    if (isLoading) return <Loading />;

    if (pluginList.length === 0) {
      return (
        <div className="plugin-module">
          <EmptyTip>
            <p>{'There are none plugins in the config table, please build and register.'}</p>
          </EmptyTip>
        </div>
      );
    }

    return (
      <div className="plugin-module">
        <PluginList pluginList={pluginList} searchParams={searchParams} />
      </div>
    );
  }
}

export default PluginModule;
