import React from 'react';
import { Link } from '@reach/router';
import { mediaUrl, siteRoot, gettext } from '../utils/constants';

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        style: {
          color: isCurrent ? '#efa350' : '#666666',
          borderBottom: isCurrent ? '2px solid #efa350' : '2px solid transparent'
        }
      };
    }}
  />
);

class HeaderPanel extends React.Component {

  render() {
    return (
      <div id="header" className="plugin-market-header">
        <a href={siteRoot} className="position-absolute"><img src={`${mediaUrl}img/logo.png`} alt="logo" width="128" /></a>
        <div className="plugin-toolbar">
          <NavLink to={siteRoot} className="plugin-toolbar-item">
            {gettext('Plugins')}
          </NavLink>
          <NavLink to={siteRoot + 'plugins/'} className="plugin-toolbar-item">
            {gettext('Templates')}
          </NavLink>
        </div>
      </div>
    );
  }
}

export default HeaderPanel;
