import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { processor } from '@seafile/seafile-editor/dist/utils/seafile-markdown2html';
import Loading from '../loading';
import { seatableMarketInternalAPI } from '../../utils/internal-api';
import { Utils } from '../../utils/utils';
import toaster from '../toast';

const propTypes = {
  type: PropTypes.string.isRequired,
  card: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
};

class CardDetailDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      description: null,
    };
  }

  componentDidMount(){
    let { card } = this.props;
    processor.process(card.description).then((result) => {
      let description = String(result);
      this.setState({
        isLoading: false,
        description: description
      });
    });
  }

  toggle = () => {
    this.props.toggle();
  }

  downloadPlugin = () => {
    seatableMarketInternalAPI.getPluginDownloadLink(this.props.card.zip_asset_path).then(res => {
      location.href = res.data.download_link;
    }).catch(error => {
      let errMessage = Utils.getErrorMsg(error);
      toaster.danger(errMessage);
    });
  }

  onExternalLinkClick = () => {
    let { card } = this.props;
    location.href = card.link;
  }

  render() {
    let { type, card } = this.props;
    let { isLoading, description } = this.state;
    return (
      <Modal isOpen={true} className="card-detail-dialog" toggle={this.toggle} contentClassName={'card-detail-container'}>
        <ModalHeader toggle={this.toggle}>{type === 'plugin' ? '插件' : '模版'}</ModalHeader>
        <ModalBody className="card-detail-content">
          {isLoading && <Loading />}
          {!isLoading && (
            <Fragment>
              <div className="card-detail-image">
                <img src={card.card_image_url} alt="pluign-icon"/>
              </div>
              <div className="card-detail-info">
                <div className="card-detail-name text-truncate">{card.display_name}</div>
                <div>
                  {card.zip_asset_path && card.zip_asset_path.length > 0 &&
                    <Button color='green' className="card-detail-look" onClick={this.downloadPlugin}>{'下载'}</Button>
                  }
                  {card.link && 
                    <Button color='green' className="card-detail-look ml-4" onClick={this.onExternalLinkClick}>{'查看'}</Button>
                  }
                </div>
              </div>
              <div className="card-detail-instruction" dangerouslySetInnerHTML={{__html: description}}></div>
            </Fragment>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

CardDetailDialog.propTypes = propTypes;

export default CardDetailDialog;
